import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Image from '../components/Image'
import { Carousel } from '../components/Carousel'
import Previewable from '../components/Previewable'
import SEO from '../components/SEO'
import { Banner } from '../acf/Banner/Banner.jsx'
import { FormBlock } from '../acf/FormBlock/FormBlock.jsx'
import { decodeEntities, getExcerpt } from '../utils/htmlParse'
import RelatedItemsBlock from '../components/RelatedItemsBlock'
import './casestudy.scss'

const CaseStudy = ({ data, location }) => {
  const {
    wordpressPost: caseStudy,
    allCaseStudyServices,
    relatedCaseStudies,
    site,
  } = data
  if (!caseStudy) return null
  const {
    title,
    content,
    featuredImage,
    yoast,
    slug,
    case_study_service: serviceIds,
    acf,
  } = caseStudy
  const myServices =
    allCaseStudyServices &&
    allCaseStudyServices.nodes &&
    allCaseStudyServices.nodes.filter(
      service => serviceIds && serviceIds.includes(service.wordpress_id)
    )
  const { title: siteTitle, caseStudiesSlug } = site.siteMetadata
  const {
    projectName,
    specificationsList,
    videoHeading,
    videoDescription,
    video,
    downloadsHeading,
    downloadFiles,
    gallery,
  } = acf
  return (
    <Layout location={location} className="case-study-template">
      <SEO
        title={`${
          yoast.metaTitle
            ? decodeEntities(yoast.metaTitle)
            : `${decodeEntities(title)} | ${decodeEntities(siteTitle)}`
        }`}
        desc={yoast.metaDescription}
      />
      <Banner
        smallHeading="Pivotal Project"
        heading={title}
        image={featuredImage}
        location={location}
        customCrumbs={[
          {
            link: '/',
            title: 'Home',
          },
          {
            link: `/${caseStudiesSlug}/`,
            title: 'Pivotal Project',
          },
        ]}
      />
      <section className="post-container">
        <div className="wrapper">
          <span className="project-summary-heading">Project Summary</span>
          <div
            className="post-content-body"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </section>
      {specificationsList && (
        <section className="specifications-list-container">
          <div className="wrapper">
            <h2 className="specifications-list-heading">Specifications</h2>
            <div className="specifications-list">
              <div className="specifications-list-item">
                <div className="specifications-list-item-heading">
                  <h3>Project</h3>
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: projectName || title }}
                  className="specifications-list-item-content"
                />
              </div>
              {specificationsList.map((spec, index) => {
                const { specTitle, specContent } = spec
                return (
                  <div key={index} className="specifications-list-item">
                    <div className="specifications-list-item-heading">
                      <h3>{specTitle}</h3>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{ __html: specContent }}
                      className="specifications-list-item-content"
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      )}
      {video && (
        <section className="case-study-video-container">
          <div className="wrapper">
            {videoHeading && (
              <h2 className="video-heading">{decodeEntities(videoHeading)}</h2>
            )}
            {videoDescription && (
              <p className="video-description">
                {decodeEntities(videoDescription)}
              </p>
            )}
            <div className="video-wrapper">
              <div
                dangerouslySetInnerHTML={{ __html: video }}
                className="video"
              />
            </div>
          </div>
        </section>
      )}
      {gallery && (
        <section className="case-study-gallery">
          <div className="wrapper">
            <h2 className="case-study-gallery-heading">Project Gallery</h2>
            <Carousel
              infinite
              stagePadding={{
                paddingLeft: 110,
                paddingRight: 110,
              }}
            >
              {gallery.map((image, index) => {
                return (
                  <div key={index} className="case-study-gallery-item">
                    <Image image={image} />
                  </div>
                )
              })}
            </Carousel>
          </div>
        </section>
      )}
      {downloadFiles && downloadFiles.length > 0 && (
        <section className="case-study-downloads">
          <div className="wrapper">
            <span className="downloads-small-heading">Downloads</span>
            <h2 className="downloads-heading">
              {decodeEntities(downloadsHeading)}
            </h2>
            {downloadFiles.map((downloadItem, index) => {
              const { file, linkLabel } = downloadItem
              return (
                <div key={index} className="download-item">
                  <a
                    href={file.url.source_url || file.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {decodeEntities(linkLabel || file.filename)}
                  </a>
                </div>
              )
            })}
          </div>
        </section>
      )}
      {myServices && myServices[0] && (
        <FormBlock
          heading={`For further information on the latest in ${myServices[0].name}, fill out the form below.`}
          smallHeading="Contact"
          form="general"
          location={location}
        />
      )}
      {relatedCaseStudies &&
        relatedCaseStudies.nodes &&
        relatedCaseStudies.nodes.length > 0 && (
          <RelatedItemsBlock
            heading="Related Case Studies"
            viewMoreText="View all case studies"
            viewMoreLink={`/${caseStudiesSlug}/`}
            items={relatedCaseStudies.nodes.map((cs, index) => ({
              ...cs,
              services:
                allCaseStudyServices &&
                allCaseStudyServices.nodes &&
                allCaseStudyServices.nodes.filter(
                  s => cs.serviceIds && cs.serviceIds.includes(s.wordpress_id)
                ),
              excerpt: getExcerpt(cs.content, 100),
            }))}
          />
        )}
    </Layout>
  )
}

export default Previewable(CaseStudy, 'case_study')

export const pageQuery = graphql`
  query CaseStudyByID($id: String!, $serviceIds: [Int]) {
    site {
      siteMetadata {
        title
        functionsUrl
        caseStudiesSlug
      }
    }
    wordpressPost: wordpressWpCaseStudy(id: { eq: $id }) {
      id
      title
      slug
      content
      case_study_service
      wordpress_id
      date(formatString: "MMMM DD, YYYY")
      featuredImage: featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      acf {
        projectName
        specificationsList {
          specTitle
          specContent
        }
        videoHeading
        videoDescription
        video
        downloadsHeading
        downloadFiles {
          file {
            filename
            title
            url
          }
          linkLabel
        }
        gallery {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      yoast {
        metaTitle: title
        metaDescription: metadesc
      }
    }
    relatedCaseStudies: allWordpressWpCaseStudy(
      filter: {
        case_study_service: { in: $serviceIds }
        slug: { ne: "gatsby-build-case-study" }
        id: { ne: $id }
      }
      limit: 3
    ) {
      nodes {
        title
        content
        slug
        link
        serviceIds: case_study_service
        featuredImage: featured_media {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allCaseStudyServices: allWordpressWpCaseStudyService {
      nodes {
        wordpress_id
        wordpress_parent
        slug
        name
      }
    }
  }
`
